.iks-icon {
  display: inline-block;
  font-size: 16px;

  // Icon sizes
  &--hourglass {
    width: 13px;
    height: 17px;
  }

  &--check-yellow {
    width: 18px;
    height: 14px;
  }

  &--arrow-down {
    transform: rotate(-90deg);
  }

  &--arrow-down,
  &--arrow-back {
    width: 13px;
    height: 22px;
  }

  &--expl {
    width: 34px;
    height: 35px;
  }

  &--inspect {
    width: 36px;
    height: 36px;
  }

  &--spinner {
    width: 80px;
    height: 80px;
  }

  &--plus,
  &--minus,
  &--plus-minus {
    width: 1.4em;
    height: 1.4em;
  }
}