@import './general/mixins';
@import './framework/scss-variables';
@import './framework/colors';

@import './fonts';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input,
textarea,
div,
p,
span,
a {
  font-family: 'Montserrat',
  sans-serif;
  color: $text;
  font-size: 18px;
  line-height: 1.5em;

  @include media('<small') {
    font-size: 16px;
  }
}

h1,
.iks-h1 {
  font-size: 50px;//62px;
  font-weight: bold;
  line-height: 1.1em;

  @include media('<medium') {
    font-size: 44px;
  }

  @include media('<small') {
    font-size: 32px;
  }

  @include media('<=tiny') {
    font-size: 28px;
  }
}

h2,
.iks-h2 {
  font-size: 38px;
  font-weight: bold;
  line-height: 1.1em;

  @include media("<small") {
    font-size: 26px;
  }
}

h3,
.iks-h3 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3em;

  @include media("<small") {
    font-size: 22px;
  }
}

h4,
.iks-h4 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3em;

  @include media("<small") {
    font-size: 16px;
  }
}

.iks-lead {
  font-size: 22px;
  line-height: 1.4em;

  @include media('<small') {
    font-size: 18px;
  }
}

.iks-reg {
  font-size: 18px;
  line-height: 1.5em;

  @include media('<small') {
    font-size: 16px;
  }
}

.iks-small {
  font-size: 16px;
  line-height: 1.5em;

  @include media('<small') {
    font-size: 14px;
  }
}

.iks-tiny {
  font-size: 14px;
  line-height: 1.3em;

  @include media('<small') {
    font-size: 12px;
  }
}

.iks-xtiny {
  font-size: 13px;
  line-height: 1.4em;

  @include media('<small') {
    font-size: 11px;
  }
}

textarea,
.iks-textarea {
  padding: 19px;
  background-color: $white;
  border: 1px solid $grey-line-3;
  border-radius: 10px;
}


// Colors
.iks-primary {
  color: $primary;
}

.iks-text-2 {
  color: $text-2;
}

.iks-black {
  color: $black;
}

.iks-grey {
  color: $grey;
}

.iks-link-color {
  color: $link;
}

.iks-red {
  color: $red;
}

// Mods
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.iks-upper {
  text-transform: uppercase;
}

.iks-medium {
  font-weight: 500;
}

.iks-strong {
  font-weight: bold;
}

.iks-link {
  color: $link;
  cursor: pointer;
  transition: filter .2s;

  &:hover {
    filter: brightness(70%);
  }
}

.iks-preline {
  white-space: pre-line;
}

.iks-center {
  text-align: center;
}