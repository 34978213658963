@import 'assets/scss/general/mixins';
@import 'assets/scss/framework/scss-variables';

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $white;
    opacity: 0.9;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
}