@import './colors';

// Media queries breakpoints
$extratiny:     360px;
$tiny:          600px;
$small:         768px;
$medium:        992px;
$large:         1440px;
$extralarge:    1600px;

// Sizes
$wrap:          1440px;
$gapDesktop:    20px;
$gapMobile:     16px;

// settings of "include-media"
$breakpoints: (
  extratiny: $extratiny,
  tiny: $tiny,
  small: $small,
  medium: $medium,
  large: $large,
  extralarge: $extralarge
);
