@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'),
      url('/assets/fonts/Montserrat-Regular.woff') format('woff'),
      url('/assets/fonts/Montserrat-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'),
      url('/assets/fonts/Montserrat-SemiBold.woff') format('woff'),
      url('/assets/fonts/Montserrat-SemiBold.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'),
      url('/assets/fonts/Montserrat-Bold.woff') format('woff'),
      url('/assets/fonts/Montserrat-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}